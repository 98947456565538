'use client';

import Link from 'next/link';

import SocialLinks from './SocialLinks';

const FooterLinks: React.FC = () => {
  return (
    <>
      <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
        <div className="mb-8 lg:mb-0">
          <h4 className="info-header">Información General</h4>
          <ul className="list-secondary-gap-2">
            <li>
              <Link href="/info/bet-rules">Reglas de apuestas</Link>
            </li>
            <li>
              <Link href="/info/deposits">Depósitos</Link>
            </li>
            <li>
              <Link href="/info/withdrawals">Retiros</Link>
            </li>
            <li>
              <Link href="/info/betting-limit">Límite de Apuestas</Link>
            </li>
          </ul>
        </div>
        <div className="mb-8 lg:mb-0">
          <h4 className="info-header">Nuestras Secciones</h4>
          <ul className="list-secondary-gap-2">
            <li>
              <Link href="/" prefetch={false}>Inicio</Link>
            </li>
            <li>
              <Link href="/casino" prefetch={false}>Casino</Link>
            </li>
            <li>
              <Link href="/deportes" prefetch={false}>Deportes</Link>
            </li>
            <li>
              <Link href="/dashboard" prefetch={false}>Perfil</Link>
            </li>
            <li>
              <Link href="/dashboard/withdrawals" prefetch={false}>Retiros</Link>
            </li>
          </ul>
        </div>
        <div className="mb-8 lg:mb-0">
          <h4 className="info-header">Seguridad & Privacidad</h4>
          <ul className="list-secondary-gap-2">
            <li>
              <Link href="/info/privacy">Política de Privacidad</Link>
            </li>
            <li>
              <Link href="/info/terms-and-conditions">Términos y Condiciones</Link>
            </li>
            <li>
              <Link href="/info/bonus-conditions">Condiciones de Bonos</Link>
            </li>
            <li>
              <Link href="/info/responsibility">Juego Responsable</Link>
            </li>
            <li>
              <Link href="/info/kyc-policies">Políticas KYC</Link>
            </li>
            <li>
              <Link href="/info/money-laundering">Anti-Lavado de Dinero</Link>
            </li>
          </ul>
        </div>
        <div className="mb-8 lg:mb-0">
          <h4 className="info-header">¿Necesitas ayuda?</h4>
          <ul className="list-secondary-gap-2">
            <li>
              <Link href="/info/faq">Preguntas Frecuentes</Link>
            </li>
            <li>
              <Link href="https://api.whatsapp.com/send?phone=50492062735&text=%C2%A1Hola">
                Contáctanos
              </Link>
            </li>
            <li>
              <Link href="/info/dispute-resolution">Resolución de Disputas</Link>
            </li>
            <li>
              <Link href="/nuevo-agente" prefetch={false}>¿Quieres ser Agente Hondubet?</Link>
            </li>
          </ul>
        </div>
      </div>
      <SocialLinks />
    </>

  );
};

export default FooterLinks;
